<template>
	<page-header-wrapper>
		<template slot="extra">
		    <a-button type="primary" @click="add" v-action:add>新增</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="6">
						<a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" :xxl="6" v-action:query>
							<a-form-item label="关键词">
								<a-input placeholder="请输入" v-model="queryParam.key" style="width:160px" />
							</a-form-item>
						</a-col>
						<a-col :xs="24" :sm="24" :md="8" :lg="9" :xl="6" :xxl="6" v-action:query>
							<a-form-item label="状态">
								<a-select placeholder="请选择" v-model="queryParam.status" style="width:160px">
									<a-select-option value="99">全部</a-select-option>
									<a-select-option value="2">启用</a-select-option>
									<a-select-option value="1">禁用</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :xs="12" :sm="12" :md="5" :lg="4" :xl="6" xxl="6" v-action:query>
							<span class="table-page-search-submitButtons">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="reset">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>
			<s-table :rowKey="(record) => record.id" ref="table" size="default" :columns="columns" :data="loadData" :scroll="{x:1350}" :pageNum="pageNum">
				<span slot="serial" slot-scope="text, record, index">
					{{ index + 1 }}
				</span>
				<span slot="state_role" slot-scope="status,record">
					<a-tag color="#87d068" v-if="status==1">启用</a-tag>
					<a-tag color="#909399" v-else-if="status==2">禁用</a-tag>
				</span>
				<span slot="createTime" slot-scope="text">{{ text }}</span>
				<span slot="login_ip" slot-scope="login_ip">
					{{login_ip||'-'}}
				</span>
				<span slot="action" slot-scope="text, record">
					<a @click="handleEdit(record)" v-action:edit>编辑</a>
					<template v-if="uuid != record.id">
						<a-divider type="vertical" /> 
						<a href="javascript:;" @click="delUser(record)" v-action:del>删除</a>
					</template>
					<a-divider type="vertical" />
					<a v-action:setStatus v-if="record.status==1" @click="setStatus(record.id,2)">禁用</a>
					<a v-action:setStatus v-else-if="record.status==2" @click="setStatus(record.id,1)">启用</a>
					<a-divider type="vertical" />
					<a href="javascript:;" @click="goDetail(record)" v-action:setAuth>分配权限</a>
				</span>
			</s-table>

			<a-modal title="操作" style="top: 20px;" :width="480" v-model="visible" @ok="handleOk" class="modal_foot_center">
				<a-form class="permission-form" :form="form" labelAlign="left">
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="真实姓名">
						<a-input placeholder="请输入真实姓名"  v-decorator="['realname',{ rules: [{ required: true, message: '请输入真实姓名!'}]}]" />
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="用户名">
						<a-input placeholder="请输入用户名" v-decorator="['name',{ rules: [{ required: true, message: '请输入用户名!'}]}]" />
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="密码" v-if="id">
						<a-input placeholder="请输入密码" @change="getPwd"
							v-decorator="['password',{ rules: [
								{ min: 6, message: '密码长度不低于6位'}
							]}]" 
						/>
					</a-form-item>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="密码" v-else>
						<a-input placeholder="请输入密码"  @change="getPwd"
							v-decorator="['password',{ rules: [
								{ required: true, message: '请输入密码!'},
								{ min: 6, message: '密码长度不低于6位'}
							]}]" 
						/>
					</a-form-item>
					<div class="input_span">
						<label style="margin-left:69px;"></label>
						<span id="one"></span>
						<span id="two"></span>
						<span id="three"></span>
					</div>
					<div id="font">
						<span>弱</span>
						<span>中</span>
						<span>强</span>
					</div>
					<a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="选择角色">
						<a-select v-decorator="['roleId',{ rules: [{ required: true, message: '请选择角色!'}]}]">
							<a-select-option :value="item.id" v-for="(item,index) in roleData" :key="index">{{item.name}}</a-select-option>
						</a-select>
					</a-form-item>
					<!-- <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="描述">
						<a-textarea :rows="5" placeholder="请输入角色描述" id="describe" v-decorator="['describe']" />
					</a-form-item> -->
				</a-form>
			</a-modal>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		allRoleList,
		addUser,
		editUser,
		getUserList,
		delUser,
		setUserStatus
	} from '@/api/system'
	import storage from 'store'
	import { getUrlKey } from '@/utils'
	import pick from 'lodash.pick'
	import {
		STable
	} from '@/components'
	import {
		getRoleList,
		getServiceList
	} from '@/api/manage'
	import {
		PERMISSION_ENUM
	} from '@/utils/helper/permission'

	const STATUS = {
		1: '启用',
		2: '禁用'
	}

	const columns = [
		{
			title: '序号',
			scopedSlots: {
				customRender: 'serial'
			},
			align:'center'
		},
		{
			title: '状态',
			dataIndex:'status',
			scopedSlots: {
				customRender: 'state_role'
			},
			align:'center'
		},
		{
			title: '用户名',
			dataIndex: 'account',
			align:'center',
			width:'200px'
		},
		// {
		// 	title: '邮箱',
		// 	dataIndex: 'email',
		// 	align:'center'
		// },
		{
			title: '真实姓名',
			dataIndex: 'realname',
			align:'center'
		},
		{
			title: '角色',
			dataIndex: 'gname',
			align:'center'
		},
		// {
		// 	title: '登陆次数',
		// 	dataIndex: 'times',
		// 	align:'center'
		// },
		{
			title: '登陆IP',
			dataIndex: 'login_ip',
			scopedSlots: {
				customRender: 'login_ip'
			},
			align:'center'
		},
		{
			title: '创建时间',
			dataIndex: 'reg_time',
			scopedSlots: {
				customRender: 'createTime'
			},
			align:'center'
		},
		// {
		// 	title: '激活人',
		// 	dataIndex: 'register',
		// 	scopedSlots: {
		// 		customRender: 'register'
		// 	},
		// 	align:'center'
		// },
		{
			title: '操作',
			width: '230px',
			dataIndex: 'action',
			scopedSlots: {
				customRender: 'action'
			},
			align:'center'
		}
	]

	export default {
		name: 'TableList',
		components: {
			STable
		},
		data() {
			return {
				description: '',
				visible: false,
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 5
					}
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					}
				},
				form: this.$form.createForm(this),
				permissions: [],

				// 高级搜索 展开/关闭
				advanced: false,
				// 查询参数
				queryParam: {
					key:'',
					status:'99'
				},
				// 表头
				columns,
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					if(requestParameters.status==99){
						requestParameters.status = ''
					}
					return getUserList(requestParameters)
						.then(res => {
							return res.data
						})
				},
				expandedRowKeys: [],
				selectedRowKeys: [],
				selectedRows: [],
				roleData:[],
				uid:'',       //用户id
				uuid:storage.get('uid'),  //当前用户id
				id:'',
				pageNum:0,
				msgText:'',
				pwdee:'',
			}
		},
		filters: {
			statusFilter(key) {
				return STATUS[key]
			},
			permissionFilter(key) {
				const permission = PERMISSION_ENUM[key]
				return permission && permission.label
			}
		},
		created() {
			this.pageNum = Number(getUrlKey('p')||0)
			this.getAllRoleList()
		},
		methods: {
			getPwd(e){
				console.log('e.target.value',e.target.value);
				this.pwdee = e.target.value;
				let newname = e.target.value;
				this.msgText = this.checkStrong(newname);
				if (this.msgText > 1 || this.msgText == 1) {
					document.getElementById("one").style.background = "red";
				} else {
					document.getElementById("one").style.background = "#eee";
				}
				if (this.msgText > 2 || this.msgText == 2) {
					document.getElementById("two").style.background = "orange";
				} else {
					document.getElementById("two").style.background = "#eee";
				}
				if (this.msgText == 4) {
					document.getElementById("three").style.background = "#00D1B2";
				} else {
					document.getElementById("three").style.background = "#eee";
				}
			},
			checkStrong(sValue) {
			      var modes = 0;
			      //正则表达式验证符合要求的
			      if (sValue.length < 1) return modes;
			      if (/\d/.test(sValue)) modes++; //数字
			      if (/[a-z]/.test(sValue)) modes++; //小写
			      if (/[A-Z]/.test(sValue)) modes++; //大写
			      if (/\W/.test(sValue)) modes++; //特殊字符
			      //逻辑处理
			      switch (modes) {
			        case 1:
			          return 1;
			          break;
			        case 2:
			          return 2;
			          break;
			        case 3:
			        case 4:
			          return sValue.length < 4 ? 3 : 4;
			          break;
			      }
			      return modes;
			},
			add(){
				this.id = ''
				this.uid = ''
				this.$nextTick(()=>{
					this.form.setFieldsValue({
					    "name": '',
					    "realname":'',
					    "roleId":''
					})
				})
				this.visible = true
			},
			handleEdit(record) {
				this.visible = true
				this.uid = record.id
				this.id = record.id
				this.$nextTick(()=>{
					this.form.setFieldsValue({
					    "name": record.account,
					    "realname":record.realname,
					    "roleId":record.gid
					})
				})
			},
			getAllRoleList(){   //角色
				let params = {}
				allRoleList(params).then(res =>{
					if(res.code==200){
						this.roleData = res.data
					}
				}).catch(res =>{
					
				})
			},
			setStatus(id,type){   //禁用恢复
				let params = {
					id:id,
					type:type
				}
				setUserStatus(params).then(res =>{
					if(res.code==200){
						this.$message.success(res.msg)
						this.$refs.table.refresh(true)
					}else{
						this.$message.error(res.msg)
					}
				}).catch(res =>{
					
				})
			},
			delUser(data){
				let that = this
				this.$confirm({
					content:'确认删除吗?',
					onOk(){
						delUser({id:data.id}).then(res =>{
							if(res.code==200){
								that.$message.success(res.msg)
								that.$refs.table.refresh(true)
							}else{
								that.$message.error(res.msg)
							}
						}).catch(res =>{
							
						})
					}
				})
			},
			handleOk(e) {
				e.preventDefault()
				this.form.validateFields((err, values) => {
					console.log(err, values)
					let params = {
						account:values.name,
						password:values.password,
						realname:values.realname,
						gid:values.roleId,
						id:this.id
					}
					if(!this.uid){
						addUser(params).then(res =>{
							if(res.code==200){
								this.$message.success(res.msg)
								this.visible = false
								this.$refs.table.refresh(true)
								this.resetForm()
							}else{
								this.$message.error(res.msg)
							}
						}).catch(res =>{})
					}else{
						editUser(params).then(res =>{
							if(res.code==200){
								this.$message.success(res.msg)
								this.visible = false
								this.$refs.table.refresh(true)
								this.resetForm()
							}else{
								this.$message.error(res.msg)
							}
						}).catch(res =>{})
					}
					
				})
			},
			resetForm(){
				this.uid = ''
				this.$nextTick(()=>{
				    this.form.setFieldsValue({
				        "name": '',
				        "realname":'',
				        "roleId":'',
				        "password":''
				    })
				})
			},
			reset(){
				this.queryParam.status='99'
				this.queryParam.key = ''
				this.$refs.table.refresh(true)
			},
			goDetail(data){  //详情
				this.pageNum = Number(getUrlKey('p'))||0
				this.$router.push({path:'/system/userEdit?uid='+data.id})
			},
			onChange(selectedRowKeys, selectedRows) {
				this.selectedRowKeys = selectedRowKeys
				this.selectedRows = selectedRows
			},
			handleExpand(expanded, record) {
				console.log('expanded', expanded, record)
				if (expanded) {
					this.expandedRowKeys.push(record.id)
				} else {
					this.expandedRowKeys = this.expandedRowKeys.filter(item => record.id !== item)
				}
			},
			toggleAdvanced() {
				this.advanced = !this.advanced
			}
		},
		watch: {
			/*
			  'selectedRows': function (selectedRows) {
			    this.needTotalList = this.needTotalList.map(item => {
			      return {
			        ...item,
			        total: selectedRows.reduce( (sum, val) => {
			          return sum + val[item.dataIndex]
			        }, 0)
			      }
			    })
			  }
			  */
		}
	}
</script>

<style lang="less" scoped>
	.permission-form {
		/deep/ .permission-group {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.input_span span {
	  display: inline-block;
	  width: 98px;
	  height: 8px;
	  background: #eee;
	  line-height: 20px;
	  margin-top:-15px;
	}
	
	#one {
	  border-top-left-radius: 5px;
	  border-bottom-left-radius: 5px;
	  border-right: 0px solid;
	  margin-left: 20px;
	  margin-right: 3px;
	}
	
	#two {
	  border-left: 0px solid;
	  border-right: 0px solid;
	  margin-left: -5px;
	  margin-right: 3px;
	}
	
	#three {
	  border-top-right-radius: 5px;
	  border-bottom-right-radius: 5px;
	  border-left: 0px solid;
	  margin-left: -5px;
	}
	#font span{
		font-size: 12px;
	}
	#font span:nth-child(1) {
	  color: red;
	  margin-left: 88px;
	}
	#font span:nth-child(2) {
	  color: orange;
	  margin: 0 128px;
	}
	#font span:nth-child(3) {
	  color: #00d1b2;
	}
</style>
